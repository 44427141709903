import React from 'react'
import cn from 'classnames'

import { StatusMessage } from 'components/status-message'

import { useSocialRedirect } from 'lib/hooks/socialAuth'
import useTranslation from 'lib/hooks/useTranslation'

import { EVENTS } from 'lib/constants/events'

import { GoogleButton } from '../google-button'
import { FacebookButton } from '../facebook-button'
import { KrisFlyerButton } from '../kris-flyer-button'

import s from './styles.module.scss'

interface ComponentProps {
  trackEvent: TrackEventType
  mode: PrimaryAuthScreens
  iconOnly?: boolean
  iconPosition?: IconPosition
  buttonsLayoutClassName?: string
}

const SocialAuthButtons: React.FC<ComponentProps> = ({
  trackEvent,
  mode,
  iconOnly,
  iconPosition = 'left',
  buttonsLayoutClassName,
}) => {
  const { goToSocialAuthUrl, error } = useSocialRedirect({ authScreen: mode })
  const { t } = useTranslation()

  const handleSocialAuth = async (provider: OauthProvider) => {
    trackEvent({
      attributeId: mode === 'login' ? EVENTS.LOGIN : EVENTS.SIGNUP,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
      attributeValue: {
        loginType: provider?.toLowerCase(),
      },
    })

    goToSocialAuthUrl(provider)
  }

  return (
    <div className={s.container}>
      {error && <StatusMessage type="error">{error}</StatusMessage>}

      <div className={cn(s.buttons, buttonsLayoutClassName)}>
        <KrisFlyerButton
          id="kris-flyer-signup-btn"
          iconOnly={iconOnly}
          iconPosition={iconPosition}
          mode={mode}
          type="submit"
        >
          {t('action.continueWithBrand', { ns: 'auth', brand: t('brand.krisflyer', { ns: 'auth' }) })}
        </KrisFlyerButton>

        <GoogleButton
          id="google-signup-btn"
          iconOnly={iconOnly}
          iconPosition={iconPosition}
          onClick={() => handleSocialAuth('GOOGLE')}
        >
          {t('action.continueWithBrand', { ns: 'auth', brand: t('brand.google', { ns: 'auth' }) })}
        </GoogleButton>
        <FacebookButton
          id="facebook-signup-btn"
          iconOnly={iconOnly}
          iconPosition={iconPosition}
          onClick={() => handleSocialAuth('FACEBOOK')}
        >
          {t('action.continueWithBrand', { ns: 'auth', brand: t('brand.facebook', { ns: 'auth' }) })}
        </FacebookButton>
      </div>
    </div>
  )
}

export { SocialAuthButtons }
