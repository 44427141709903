import React, { useState } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'

import { Icon } from 'ui/icon'
import { Button } from 'ui/button'

import { OtpModal } from 'components/otp-modal/verify'

import useTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'

import { matchPath } from 'lib/utils'
import { setStorageItem } from 'lib/utils/web-storage'

import { NEW_ACCOUNT_CREATED_FLAG } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import Gift from 'brand-assets/illustrations/icons/gift.svg'

import { PrivacyPolicy } from '../privacy-policy'
import { SocialAuthButtons } from '../social-auth'
import { EmailButton } from '../email-button'
import { EmailView } from './email-view'

import s from './styles.module.scss'
import common from '../styles.module.scss'

type SignupProps = {
  switchScreen: SwitchScreen
  setExistingSocialInfo: (data: ExistingSocialInfo) => void
  trackEvent: TrackEventType
  trackErrorEvent: TrackErrorEventType
  trackSuccessEvent: TrackSuccessEventType
  custom: Record<string, string>
}

const isDevOrTestEnv = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'

const Signup: React.FC<SignupProps> = ({
  custom,
  switchScreen,
  setExistingSocialInfo,
  trackEvent,
  trackErrorEvent,
  trackSuccessEvent,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [verifyData, setVerifyData] = useState<OtpVerifyCustomerData | null>(null)
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const [isOtpModalOpen, setIsOtpModalOpen] = useState<boolean>(false)
  const { handleUserResponse, getPostAuthRedirectUrl, accountCreatedToast, authModal } = useAuth()

  const resetCaptcha = () => {
    if (isDevOrTestEnv) return
    if (window.grecaptcha) window.grecaptcha.reset()
  }

  const onOtpModalClose = () => {
    setIsOtpModalOpen(false)
    resetCaptcha()
  }

  const onSignUpSuccess = (data: OtpVerifyCustomerData) => {
    setIsOtpModalOpen(true)
    setVerifyData(data)
  }

  const logInUser = (data: UserResponse) => {
    handleUserResponse(data)

    const redirect = getPostAuthRedirectUrl()
    const isUserOnSameRedirectPage = matchPath(router.asPath, redirect)

    if (isUserOnSameRedirectPage) {
      setStorageItem(NEW_ACCOUNT_CREATED_FLAG, !!data.isCreated)
      router.replace({ pathname: redirect })
      window?.location?.reload()
    } else {
      router.replace(redirect)
      if (authModal.isOpen) authModal.close()
      if (data.isCreated) accountCreatedToast.show()
    }
  }

  return (
    <div className={cn(s.signup, common.screen)}>
      <div className={common.header}>
        {showEmailForm && (
          <button className={cn(common.backBtn, 'cursor-pointer')} onClick={() => setShowEmailForm(false)}>
            <Icon name="caret-left" color="#000" />
          </button>
        )}
        <div>
          <h2 className={common.title}>{custom?.title ? custom.title : t('t.signUp', { ns: 'common' })}</h2>
          <p className={common.subtitle}>
            {t('exclm.enjoyRewardsAndStartEarningMilesOnBooking', { ns: 'common' })}
          </p>
        </div>
        <Gift />
      </div>

      <div className={common.content}>
        <div className={common.topContent}>
          {showEmailForm ? (
            <>
              <h3 className={common.continueWithEmail}>{t('action.continueWithEmail', { ns: 'auth' })}</h3>
              <EmailView
                switchScreen={switchScreen}
                setExistingSocialInfo={setExistingSocialInfo}
                trackEvent={trackEvent}
                trackErrorEvent={trackErrorEvent}
                trackSuccessEvent={trackSuccessEvent}
                onSignUpSuccess={onSignUpSuccess}
              />
            </>
          ) : (
            <>
              <SocialAuthButtons
                trackEvent={trackEvent}
                mode="signup"
                iconPosition="right"
                buttonsLayoutClassName={cn(common.socialButtonsLayout)}
              />
              <EmailButton
                id="email-login-btn"
                iconPosition="right"
                customClassName={cn(common.emailButton)}
                onClick={() => {
                  setShowEmailForm(true)
                  trackEvent({
                    attributeId: EVENTS.SIGNUP,
                    attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
                    attributeValue: {
                      signupType: 'email',
                    },
                  })
                }}
              >
                {t('action.continueWithEmail', { ns: 'auth' })}
              </EmailButton>
            </>
          )}
        </div>

        <div className={common.bottomContent}>
          <div className={common.authSwitcher}>
            <span>{t('q.alreadyHaveAnAccount')}</span>
            <Button variant="link-tertiary" onClick={() => switchScreen?.('login')} size="small">
              {t('t.logIn', { ns: 'common' })}
            </Button>
          </div>
          <PrivacyPolicy />
        </div>
      </div>
      <OtpModal
        dataToSend={verifyData}
        isOpen={isOtpModalOpen}
        onClose={onOtpModalClose}
        successCallback={logInUser}
      />
    </div>
  )
}

export { Signup }
