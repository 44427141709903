import React, { useState, useEffect } from 'react'
import posthog from 'posthog-js'

import useAuth from 'lib/hooks/useAuth'

import { getDsUserId } from 'lib/utils/auth'

import { EVENTS } from 'lib/constants/events'
import { DATA_LAYER_EVENT } from 'lib/constants'

import { Login } from './login'
import { Signup } from './signup'
import { Lockout } from './lockout'
import { ExistingAccount } from './existing-account'

interface AuthFlowProps {
  intialScreen: AuthScreens
  beforeScreenSwitch?: (nextScreen: AuthScreens) => void
  trackEvent: TrackEventType
  onCloseModal?: () => void
  custom: Record<string, string> | null
}

const SCREEN_NAME_TO_COMPONENT: Record<AuthScreens, React.ElementType> = {
  signup: Signup,
  login: Login,
  lockout: Lockout,
  existingAccount: ExistingAccount,
}

const AuthFlow: React.FC<AuthFlowProps> = ({
  custom = {},
  intialScreen = 'login',
  beforeScreenSwitch,
  trackEvent,
  onCloseModal,
}) => {
  const { setAuthInProgress } = useAuth()
  const [activeScreen, setActiveScreen] = useState<AuthScreens>(intialScreen)
  const [socialInfo, setExistingSocialInfo] = useState<ExistingSocialInfo>({ email: '', provider: null })

  const switchScreen: SwitchScreen = (screen) => {
    beforeScreenSwitch?.(screen)
    setActiveScreen(screen)
  }

  useEffect(() => {
    if (activeScreen === 'lockout') {
      trackEvent?.({ attributeId: EVENTS.USER_LOCK_OUT, attributeType: '', eventType: '' })
    }
    // eslint-disable-next-line
  }, [activeScreen])

  const activeComponent = SCREEN_NAME_TO_COMPONENT[activeScreen]

  const trackErrorEvent = (
    attributeId: string,
    authAction: AuthAction,
    provider: string,
    error: string,
    isPasswordLess: boolean
  ) => {
    trackEvent?.({
      error,
      attributeId,
      eventType: EVENTS.TYPE.EXPOSURE,
      attributeType: EVENTS.ATTRIBUTES_TYPE.ERROR,
      isInteractionEvent: true,
      attributeValue: {
        [authAction]: provider?.toLowerCase(),
        isPasswordLess,
      },
    })
  }

  const trackSuccessEvent = (
    attributeId: string,
    authAction: AuthAction,
    provider: string,
    customerId: string,
    isPasswordLess: boolean
  ) => {
    const isOldDsUserId = true

    trackEvent?.({
      attributeId,
      eventType: EVENTS.TYPE.EXPOSURE,
      attributeType: EVENTS.ATTRIBUTES_TYPE.SUCCESS,
      isInteractionEvent: true,
      attributeValue: {
        isPasswordLess,
        [authAction]: provider?.toLowerCase(),
        oldDsUserId: getDsUserId(isOldDsUserId),
      },
      customerId,
    })

    const dataLayerEventName =
      attributeId === EVENTS.USER_SIGNED_UP
        ? DATA_LAYER_EVENT.REGISTRATION_COMPLETE
        : DATA_LAYER_EVENT.LOGIN_COMPLETE
    // note: below same exist in useSocialAuth also (in case any change in future)
    const payload = {
      [authAction]: provider.toUpperCase(),
      customerId,
    }

    // @ts-ignore
    window?.dataLayer?.push?.({
      event: dataLayerEventName,
      ...payload,
    })
    posthog.capture(dataLayerEventName, payload)
  }

  // make sure inprogress flag set to false when component unmounts
  useEffect(() => () => setAuthInProgress(false), []) // eslint-disable-line

  return React.createElement(activeComponent, {
    custom,
    switchScreen,
    socialInfo,
    setExistingSocialInfo,
    trackEvent,
    trackErrorEvent,
    trackSuccessEvent,
    onCloseModal,
  })
}

export { AuthFlow }
